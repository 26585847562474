<template>
    <div id="projects">
        <vue-topprogress ref="topProgress" :color="$barColor" :height="$barHeight"></vue-topprogress>
        <section id="inner-headline">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h2 class="pageTitle">Contact Us</h2>
                    </div>
                </div>
            </div>
        </section>
        <section id="content">
            <div class="container">
                <div class="row">
                    <div v-html="cms.c_map_address" class="col-lg-12"></div>
                </div>
                <div class="row" id="cform">
                    <div class="col-md-8">
                        <h3>We look forward to hearing from you!</h3>
                        <p>Please fill out the form below and click "Submit." We will get back to you as soon as possible!</p>
                        <div class="contact-form">
                            <form id="contact-form" @submit.prevent="submitCF" class="contact">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group has-feedback">
                                            <label>Name*</label>
                                            <input v-model.trim="name" name="name" type="text" class="form-control" required>
                                            <i class="fa fa-user form-control-feedback"></i>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group has-feedback">
                                            <label>Email*</label>
                                            <input v-model.trim="email" name="email" type="email" class="form-control" required>
                                            <i class="fa fa-envelope form-control-feedback"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group has-feedback">
                                            <label>Company*</label>
                                            <input v-model.trim="company" name="company" type="company" class="form-control" required>
                                            <i class="fa fa-building-o form-control-feedback"></i>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group has-feedback">
                                            <label>Subject*</label>
                                            <input v-model.trim="subject" name="subject" type="subject" class="form-control" required>
                                            <i class="fa fa-quote-right form-control-feedback"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group has-feedback">
                                            <label>Message*</label>
                                            <textarea v-model.trim="message" name="message" class="form-control" rows="6" required></textarea>
                                            <i class="fa fa-pencil form-control-feedback"></i>
                                        </div>
                                    </div>
                                </div>
                                <input type="submit" value="Submit" id="submit" class="submit btn btn-default">
                            </form>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <address v-if="cms.c_page_address" v-html="cms.c_page_address"></address>
                        <address v-if="cms.c_page_address2" v-html="cms.c_page_address2"></address>
                        <address v-if="cms.c_page_address3" v-html="cms.c_page_address3"></address>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script type="text/javascript" scoped>
import { mapState } from "vuex";

export default{
	name: "ContactView",
	computed: mapState([
		"cms",
	]),
	data(){
		return {
			name: null,
			email: null,
			company: null,
			subject: null,
			message: null,
		};
	},
	methods: {
		submitCF(){
			this.$refs.topProgress.start();
			this.$axios({
				method: "post",
				url: this.$apiURL+"submit_cf",
				data: this.$jquery("#contact-form").serialize(),
			}).then(() => {
				this.$refs.topProgress.done();
				this.$toasted.success("Your message has been sent successfully, we will contact you shortly.");
				this.name = null;
				this.email = null;
				this.company = null;
				this.subject = null;
				this.message = null;
			});
		},
	},
}
</script>

<style type="text/css" scoped>
.row .row{
	margin-bottom: 0px;
}
</style>